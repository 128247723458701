export const preview = true;
export const login = true;
export const dashboard = true;
export const fileManager = true;
export const sets = true;
export const categories = true;
export const category = true;
export const filters = true;
export const keywords = false;
export const products = true;
export const product = true;
export const news = false;
export const newsIndex = false;
export const services = true;
export const service = true;
export const orders = false;
export const order = false;
export const plans = false;
export const plan = false;
export const albums = true;
export const videos = false;
export const sliders = true;
export const users = false;
export const userInfo = false;
export const systemUsers = true;
export const branches = true;
export const branch = true;
export const subscriptions = false;
export const aboutUs = true;
export const companyValues = true;
export const companyValue = true;
export const companyHistories = false;
export const companyHistory = false;
export const SEO = false;
