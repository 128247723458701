import { filters, keywords, sets, categories } from '@/configs/translates.config';

export default {
	search: {
		en: 'search',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	from: {
		en: 'from',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	to: {
		en: 'to',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	
	add: {
		en: 'Add',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	edit: {
		en: 'Edit',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	delete: {
		en: 'delete',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	restore: {
		en: 'restore',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	save: {
		en: 'Save',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	change: {
		en: 'Change',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	cancel: {
		en: 'Cancel',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	close: {
		en: 'Close',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	back: {
		en: 'Back',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	select: {
		en: 'Select',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	confirm: {
		en: 'Confirm',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	activate: {
		en: 'activate',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	deactivate: {
		en: 'deactivate',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	upload: {
		en: 'Upload',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'are-you-sure-you-want-to': {
		en: 'Are you sure you want to',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},

	'changing-order': {
		en: 'Changing order',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'upload-images': {
		en: 'Upload images',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'SEO-fields': {
		en: 'SEO fields',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'drag-text': {
		en: 'Drag and drop image here',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'upload-more-image': {
		en: 'Upload more images',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'supported-text': {
		en: 'supported files: jpg, jpeg, png.',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},

	'please-wait-uploading-the-images': {
		en: 'Please wait uploading the images',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},

	'$-is-updated-successfully': {
		en: '{name} is updated successfully',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},
	'$-is-created-successfully': {
		en: '{name} is created successfully',
		ar: '',
		es: '',
		fr: '',
		fa: '',
	},

	headers: {
		en: {
			no: 'No',
			image: 'Image',
			video: 'Video',
			name: 'Name',
			sets: sets.en.plural.charAt(0).toUpperCase() + sets.en.plural.slice(1),
			visits: 'Visits',
			orders: 'Orders',
			revenue: 'Revenue',
			summary: 'Summary',
			order: 'Order',
			actions: 'Actions',
			category: 'Category',
			manufacturer: 'Manufacturer',
			quantity: 'Quantity',
			price: 'Price',
			title: 'Title',
			descriptions: 'descriptions',
			user: 'User',
			'created-at': 'Created at',
			total: 'Total',
			status: 'Status',
			'payment-type': 'Payment type',
			'current-status': 'Current status',
			address: 'Address',
			email: 'Email',
			phone: 'Phone',
			username: 'Username',
			link: 'Link',
			description: 'description',
			fullname: 'Fullname',
			country: 'country',
			active: 'Active',
			date: 'Date',
			featured: 'Featured',
			'in-home': 'In home',
			'main-branch': 'Main branch',
			'country-code': 'Country code',
			'money-code': 'Money code',
			'open-hours': 'Open hours',
			duration: 'Duration',
			'offer-start-date': ' Offer start date',
			'offer-end-date': ' Offer end date',
			'offer-price': ' Offer price',
		},
		ar: {},
		es: {},
		fr: {},
		fa: {},
	},

	inputs: {
		en: {
			name: 'name',
			set: sets.en.singular,
			'parent-category': `parent ${categories.en.singular}`,
			'product-name': 'product name',
			'product-category': 'product category',
			summary: 'summary',
			code: 'code',
			order: 'order',
			description: 'description',
			category: 'category',
			keywords: keywords.en.plural,
			price: 'price',
			'subscription-price': 'subscription price',
			filter: filters.en.plural,
			file: 'file',
			link: 'link',
			'is-featured': 'is featured',
			'is-main-branch': 'is main branch',
			'is-in-home-page': 'is in home page',
			'date-of-publication': 'Date of publication',
			date: 'date',
			title: 'title',
			'plan-name': 'plan name',
			duration: 'duration (in months)',
			'offer-start-date': 'offer start date',
			'offer-end-date': 'offer end date',
			'offer-price': 'offer price',

			username: 'username',
			password: 'Password',
			'confirm-password': 'confirm password',
			'seo-description': 'SEO description',
			'seo-keywords': 'SEO keywords',
			
			'opening-hours': 'opening hours',
			'country-code': 'country code',
			'money-code': 'money code',
			location: 'google maps location URL',
			email: 'email',
			address: 'address',
			phone: 'phone',
			mobile: 'mobile',
			whatsapp: 'whatsapp',
			facebook: 'facebook page URL',
			telegram: 'telegram',
			'telegram-channel': 'telegram channel',
			instagram: 'instagram page URL',
			twitter: 'twitter page URL',
			youtube: 'youtube page URL',
			linkedIn: 'linkedIn page URL',
			pinterest: 'pinterest page URL',
			video: 'video',
		},
		ar: {},
		es: {},
		fr: {},
		fa: {},
	},

	messages: {
		en: {
			'deleted-successfully': 'Delete operation completed successfully',
			'restored-successfully': 'Restore operation completed successfully',
			'there-is-uploaded-file': 'There\'s an uploaded file',
			'item-order-is-updated': 'Item order is updated',
			'image-uploaded': 'Image uploaded successfully',
			'image-deleted': 'Image deleted successfully',
			'session-is-end': 'Your session is END, please Login again',
			uploading: 'Are you sure you want to reload the page? uploading operation will be cancelled',
			'check-your-connection': 'please, check your internet connection',
			copied: 'Copied to the clipboard',
		},
		ar: {
			'deleted-successfully': 'عملية  الحذف تمت بنجاح',
			'restored-successfully': 'عملية  الاسترجاع تمت بنجاح',
			'there-is-uploaded-file': 'هناك ملف مرفوع',
			'item-order-is-updated': 'تم تحديث ترتيب العنصر',
			'image-uploaded': 'تم رفع الصورة بنجاح',
			'image-deleted': 'تم حذف الصورة بنجاح',
			'session-is-end': 'جلستك انتهت، الرجاء تسجيل الدخول مرة أخرى',
			uploading: 'هل أنت متأكد من إعادة تحميل الصحفة؟ هذا سوف يلغي عملية الرفع',
			'check-your-connection': 'رجاءً تحقق من اتصالك بالإنترنت',
			copied: 'تم النسخ إلى الحافظة',
		}
	},

	'file-manager': {
		en: {
			'the-item': 'the Item?',
			tabs: {
				images: 'images',
				videos: 'videos',
				files: 'files',
				upload: 'upload',
			},
			'no-images': 'No images.',
			'no-videos': 'No videos.',
			'no-files': 'No files.',
			'upload-type': {
				image: 'image',
				video: 'video',
				file: 'file',
			},
			loading: 'Loading ...'
		},
		ar: {},
		es: {},
		fr: {},
		fa: {},
	},

	components: {
		en: {
			'file-manager': 'File manger',
			filesFilters: {
				small: 'S',
				medium: 'M',
				large: 'L',
				original: 'Original',
			},
			fileTypes: {
				images: 'Images',
			},
			upload: 'Upload',
			'size-to-use': 'Size image to use',
			size: 'Size',
		},
		ar: {
			'file-manager': 'مدير الملفات',
			filesFilters: {
				small: 'صغير',
				medium: 'متوسطة',
				large: 'كبير',
				origanl: 'الأصلي',
			},
			fileTypes: {
				image: 'صور',
			},
			upload: 'رفع',
			'size-to-use': 'استخدم للصورة القياس',
			size: 'الحجم'
		},
		es: {
			'file-manager': '',
			filesFilters: {
				small: '',
				medium: '',
				large: '',
				origanl: '',
			},
			fileTypes: {
				image: '',
			},
			upload: 'رفع',
			'size-to-use': '',
			size: ''
		},
		fr: {
			'file-manager': '',
			filesFilters: {
				small: '',
				medium: '',
				large: '',
				origanl: '',
			},
			fileTypes: {
				image: '',
			},
			upload: '',
			'size-to-use': '',
			size: ''
		},
		fa: {
			'file-manager': '',
			filesFilters: {
				small: '',
				medium: '',
				large: '',
				origanl: '',
			},
			fileTypes: {
				image: '',
			},
			upload: '',
			'size-to-use': '',
			size: ''
		},
	}
}