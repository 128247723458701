<template>
	<div>
		<bee-delete-dialog
			v-if="false"
			v-model="deleteDialog"
			moduleType=''
			:ids="deleteItemId"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Users.the-user')}}
		</bee-delete-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-if="false"
			v-model="dialog"
			width="400"
			persistent
		>
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase text-h6 mb-3">
					{{ editItemId ? $t('edit') : $t('add')}} {{$t('Users.users')}}
				</v-card-title>
				<v-card-text class="pb-2">
					<v-row class="flex-column mt-0">
						<v-col class="py-0">
							<v-text-field
								:label="$t('inputs.name')"
								outlined
								dense
								class="rounded-small"
								:rules="rules.required"
							></v-text-field>
						</v-col>
						<v-col class="py-0">
							<v-text-field
								:label="$t('inputs.username')"
								outlined
								dense
								class="rounded-small"
								:rules="rules.required"
							></v-text-field>
						</v-col>
						<v-col class="py-0">
							<v-text-field
								:label="$t('inputs.password')"
								type="password"
								outlined
								dense
								class="rounded-small"
								:rules="rules.required"
							></v-text-field>
						</v-col>
						<v-col class="py-0">
							<v-text-field
								:label="$t('inputs.confirm-password')"
								type="password"
								outlined
								dense
								class="rounded-small"
								:rules="rules.required"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" class="rounded-small">{{$t('save')}}</v-btn>
					<v-btn @click="dialog = false; editItemId = null" class="rounded-small">{{$t('cancel')}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- confirm dialog -->
		<v-dialog
			v-model="confirmDialog"
			width="400"
			:persistent="changeStateLoading"
		>
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase text-h6">
					{{ $t('confirm') + ' ' + titleState }}
				</v-card-title>
				<v-card-text class="text-body-1 pb-2">
					{{$t('Users.are-you-sure-you-want-to-$-this-user', { state: titleState })}}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="primary"
						class="rounded-small"
						:loading="changeStateLoading"
						:disabled="changeStateLoading"
						@click="changeUserState()"
					>
						{{titleState}}
					</v-btn>
					<v-btn
						class="rounded-small"
						:disabled="changeStateLoading"
						@click="confirmDialog = false; confirmId = null"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text v-if="false" class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Users.add-new-user')}}
						</v-btn>
					</v-col>

					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
						>
							{{$t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-text v-else class="pa-3">
				<v-row class="justify-center align-center">
					<v-col lg="3" md="4" sm="6" cols="12">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium"
						></v-text-field>
					</v-col>
					<v-spacer/>
					<v-col cols="auto">
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredUsers"
			:loading="loading"
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- address -->
			<template v-slot:item.address="{ item }">
				{{ item.address | isAvailable }}
			</template>

			<!-- email -->
			<template v-slot:item.email="{ item }">
				{{ item.email | isAvailable }}
			</template>

			<!-- phone -->
			<template v-slot:item.phone="{ item }">
				{{ item.phone | isAvailable }}
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center">
					<v-btn
						v-if="showUserInfo"
						color="deep-purple darken-2 white--text"
						dark
						small
						class="me-1 px-1 flex-shrink-1"
						min-width="20px"
						:to="{ name: 'user-info', params: { id: item.id } }"
					><v-icon>mdi-eye</v-icon></v-btn>
					<v-btn
						v-if="false"
						color="blue darken-4"
						dark
						small
						class="me-1 px-1 flex-shrink-1"
						min-width="20px"
						@click="fillToEdit(item.id)"
					><v-icon>mdi-pencil</v-icon></v-btn>
					<v-btn
						v-if="false"
						color="red darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="deleteItemId = item.id; deleteDialog = true"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>

					<v-switch
						v-model="item.isBanned"
						:ripple="false"
						:true-value="0"
						:false-value="1"
						color="darkGreen"
						hide-details
						readonly
						@click="activeConfirmDialog(item)"
						class="ms-2 mt-0 pt-0"
						:disabled="item.isAdmin"
					></v-switch>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapState } from 'vuex';
import { userInfo } from '@/configs/routes.config'

export default {
	name: 'Users',

	data: () => ({
		dialog: false,		
		editItemId: null,
		
		deleteDialog: false,
		deleteItemId: null,
		
		confirmDialog: false,
		confirmId: null,
		titleState: null,
		changeStateLoading: false,

		// filter
		search: null,
		isFiltered: null,

		// helpers
		loading: false,
		rules,

		// configs
		showUserInfo: userInfo.show
	}),

	computed: {
		headers() {
			return [
				{ text: this.$t('headers.name'), value: 'fullName', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.username'), value: 'userName', align: 'center', sortable: false },
				{ text: this.$t('headers.address'), value: 'address', align: 'center', sortable: false },
				{ text: this.$t('headers.email'), value: 'email', align: 'center', sortable: false },
				{ text: this.$t('headers.phone'), value: 'phone', align: 'center', sortable: false },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			]
		},

		...mapState({
			users: state => state.users.users
		}),

		filteredUsers() {
			return this.filterByName(this.isFiltered, this.users, this.search, 'fullName').filter(c => !c.isAdmin);
		}
	},

	methods: {
		fillToEdit(id) {
			this.dialog = true;
			this.editItemId = id;
		},

		activeConfirmDialog(item) {
			this.confirmId = item.id;
			this.titleState = item.is_banned ? this.$t('activate') : this.$t('deactivate');
			this.confirmDialog = true;
		},

		changeUserState() {
			this.changeStateLoading = true;
			this.$store.dispatch('users/changeState', {
				id: this.confirmId
			}).then(() => {
				this.confirmDialog = false
				this.$eventBus.$emit('show-snackbar', false, this.$t('Users.user-state-is-changed-successfully'));
				this.fetchUsers();
			}).finally(() => {
				this.changeStateLoading = false;
			})
		},

		fetchUsers() {
			this.loading = true;
			this.$store.dispatch('users/fetchAll')
				.finally(() => {
					this.loading = false;
				})
		}
	},

	created() {
		this.fetchUsers();
	}
};
</script>

<style>
</style>
