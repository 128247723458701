<template>
	<v-form ref="form" @submit.prevent="submit">
		<!-- lang tabs -->
		<v-lang-tabs
			v-if="multiLangInputs"
			v-model="lang"
			:disable-non-selected="isInputsChanged || loading || submitLoading"
			background-color="white"
			class="mb-4"
			@change="fetchSEOConfigs"
		/>

		<!-- home page -->
		<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('SEOpage.home-page')}}</div>
		<v-row>
			<!-- SEO keywords -->
			<v-col lg="6" cols="12">
				<v-text-field
					v-model="homePage.keywords"
					:label="$t('inputs.seo-keywords')"
					outlined
					dense
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					hint="keywords should separated by (,) eg: keyword1, keyword2"
					@input="homePage.keywords = homePage.keywords.replace(/(\w) /g, '$1, '); isInputsChanged = true;"
				></v-text-field>
			</v-col>

			<!-- SEO description -->
			<v-col lg="6" cols="12">
				<v-textarea
					v-model="homePage.description"
					:label="$t('inputs.seo-description')"
					outlined
					dense
					auto-grow
					rows="1"
					no-resize
					counter
					:hint="inputsHint(homePage.description)"
					persistent-hint
					:color="inputsHint(homePage.description).length ? 'warning' : null"
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					@keypress.enter.prevent
					@input="isInputsChanged = true"
					@change="homePage.description = homePage.description.replace(/\n/g, ' ')"
				></v-textarea>
			</v-col>
		</v-row>

		<!-- products -->
		<div v-if="showProducts">
			<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('SEOpage.products-page')}}</div>
			<v-row>
				<!-- SEO keywords -->
				<v-col lg="6" cols="12">
					<v-text-field
						v-model="products.keywords"
						:label="$t('inputs.seo-keywords')"
						outlined
						dense
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						hint="keywords should separated by (,) eg: keyword1, keyword2"
						@input="products.keywords = products.keywords.replace(/(\w) /g, '$1, '); isInputsChanged = true;"
					></v-text-field>
				</v-col>
			
				<!-- SEO description -->
				<v-col lg="6" cols="12">
					<v-textarea
						v-model="products.description"
						:label="$t('inputs.seo-description')"
						outlined
						dense
						auto-grow
						rows="1"
						no-resize
						counter
						:hint="inputsHint(products.description)"
						persistent-hint
						:color="inputsHint(products.description).length ? 'warning' : null"
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						@keypress.enter.prevent
						@input="isInputsChanged = true"
						@change="products.description = products.description.replace(/\n/g, ' ')"
					></v-textarea>
				</v-col>
			</v-row>
		</div>
		
		<!-- news -->
		<div v-if="showNews">
			<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('SEOpage.news-page')}}</div>
			<v-row>
				<!-- SEO keywords -->
				<v-col lg="6" cols="12">
					<v-text-field
						v-model="news.keywords"
						:label="$t('inputs.seo-keywords')"
						outlined
						dense
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						hint="keywords should separated by (,) eg: keyword1, keyword2"
						@input="news.keywords = news.keywords.replace(/(\w) /g, '$1, '); isInputsChanged = true;"
					></v-text-field>
				</v-col>
			
				<!-- SEO description -->
				<v-col lg="6" cols="12">
					<v-textarea
						v-model="news.description"
						:label="$t('inputs.seo-description')"
						outlined
						dense
						auto-grow
						rows="1"
						no-resize
						counter
						:hint="inputsHint(news.description)"
						persistent-hint
						:color="inputsHint(news.description).length ? 'warning' : null"
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						@keypress.enter.prevent
						@input="isInputsChanged = true"
						@change="news.description = news.description.replace(/\n/g, ' ')"
					></v-textarea>
				</v-col>
			</v-row>
		</div>

		<!-- services -->
		<div v-if="showServices">
			<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('SEOpage.services-page')}}</div>
			<v-row>
				<!-- SEO keywords -->
				<v-col lg="6" cols="12">
					<v-text-field
						v-model="services.keywords"
						:label="$t('inputs.seo-keywords')"
						outlined
						dense
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						hint="keywords should separated by (,) eg: keyword1, keyword2"
						@input="services.keywords = services.keywords.replace(/(\w) /g, '$1, '); isInputsChanged = true;"
					></v-text-field>
				</v-col>
			
				<!-- SEO description -->
				<v-col lg="6" cols="12">
					<v-textarea
						v-model="services.description"
						:label="$t('inputs.seo-description')"
						outlined
						dense
						auto-grow
						rows="1"
						no-resize
						counter
						:hint="inputsHint(services.description)"
						persistent-hint
						:color="inputsHint(services.description).length ? 'warning' : null"
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						@keypress.enter.prevent
						@input="isInputsChanged = true"
						@change="services.description = services.description.replace(/\n/g, ' ')"
					></v-textarea>
				</v-col>
			</v-row>
		</div>
		
		<!-- albums -->
		<div v-if="showAlbums">
			<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('SEOpage.albums-page')}}</div>
			<v-row>
				<!-- SEO keywords -->
				<v-col lg="6" cols="12">
					<v-text-field
						v-model="albums.keywords"
						:label="$t('inputs.seo-keywords')"
						outlined
						dense
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						hint="keywords should separated by (,) eg: keyword1, keyword2"
						@input="albums.keywords = albums.keywords.replace(/(\w) /g, '$1, '); isInputsChanged = true;"
					></v-text-field>
				</v-col>
			
				<!-- SEO description -->
				<v-col lg="6" cols="12">
					<v-textarea
						v-model="albums.description"
						:label="$t('inputs.seo-description')"
						outlined
						dense
						auto-grow
						rows="1"
						no-resize
						counter
						:hint="inputsHint(albums.description)"
						persistent-hint
						:color="inputsHint(albums.description).length ? 'warning' : null"
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						@keypress.enter.prevent
						@input="isInputsChanged = true"
						@change="albums.description = albums.description.replace(/\n/g, ' ')"
					></v-textarea>
				</v-col>
			</v-row>
		</div>

		<!-- aboutUs -->
		<div v-if="showAboutUs">
			<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('SEOpage.about-us-page')}}</div>
			<v-row>
				<!-- SEO keywords -->
				<v-col lg="6" cols="12">
					<v-text-field
						v-model="aboutUs.keywords"
						:label="$t('inputs.seo-keywords')"
						outlined
						dense
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						hint="keywords should separated by (,) eg: keyword1, keyword2"
						@input="aboutUs.keywords = aboutUs.keywords.replace(/(\w) /g, '$1, '); isInputsChanged = true;"
					></v-text-field>
				</v-col>
			
				<!-- SEO description -->
				<v-col lg="6" cols="12">
					<v-textarea
						v-model="aboutUs.description"
						:label="$t('inputs.seo-description')"
						outlined
						dense
						auto-grow
						rows="1"
						no-resize
						counter
						:hint="inputsHint(aboutUs.description)"
						persistent-hint
						:color="inputsHint(aboutUs.description).length ? 'warning' : null"
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
						@keypress.enter.prevent
						@input="isInputsChanged = true"
						@change="aboutUs.description = aboutUs.description.replace(/\n/g, ' ')"
					></v-textarea>
				</v-col>
			</v-row>
		</div>

		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
		>
			{{$t('save')}}
		</v-btn>
	</v-form>
</template>

<script>
import { news, products, services, albums, aboutUs } from '@/configs/routes.config';
import { mapState } from 'vuex'
import { defaultLang, multiLangInputs } from '@/configs/translates.config';

export default {
	name: 'SEOpage',
	
	data: () => ({
		// helpers
		loading: false,
		submitLoading: false,
		isInputsChanged: false,
		lang: defaultLang,

		// configs
		showNews: news.show,
		showProducts: products.show,
		showServices: services.show,
		showAlbums: albums.show,
		showAboutUs: aboutUs.show,

		multiLangInputs,

		// data
		homePage: {
			description: null,
			keywords: null,
		},
		products: {
			description: null,
			keywords: null,
		},
		news: {
			description: null,
			keywords: null,
		},
		services: {
			description: null,
			keywords: null,
		},
		albums: {
			description: null,
			keywords: null,
		},
		aboutUs: {
			description: null,
			keywords: null,
		}
	}),

	computed: {
		...mapState({
			SEO: state => state.SEO.SEO
		})
	},

	methods: {
		inputsHint(val) {
			return val && val.length > 155 ? 'It\'s best to keep descriptions between 155-160 characters' : '';
		},

		fetchSEOConfigs() {
			this.loading = true;
			this.$store.dispatch('SEO/fetchAll', { acceptLanguage: this.lang })
				.then(() => {
					this.fillToEdit();
				})
				.finally(() => {
					this.loading = false;
				})
		},

		submit() {
			this.submitLoading = true;
			this.$store.dispatch('SEO/create', { 
				homeDescription: this.homePage.description,
				homeKeywords: this.homePage.keywords,
				
				productsDescription: this.products.description,
				productsKeywords: this.products.keywords,
				
				newsDescription: this.news.description,
				newsKeywords: this.news.keywords,
				
				servicesDescription: this.services.description,
				servicesKeywords: this.services.keywords,
				
				albumsDescription: this.albums.description,
				albumsKeywords: this.albums.keywords,
				
				aboutUsDescription: this.aboutUs.description,
				aboutUsKeywords: this.aboutUs.keywords,
				
				acceptLanguage: this.lang
			})
				.then(() => {
					this.isInputsChanged = false
					const message = this.$t('$-is-updated-successfully', { name: this.$t('SEOpage.seo-fields') })
					this.$eventBus.$emit('show-snackbar', false, message);
					if (!multiLangInputs) {
						this.$store.dispatch('SEO/fetchAll');
					} else {
						this.fetchSEOConfigs();
					}
				})
				.finally(() => {
					this.submitLoading = false;
				})
		},

		fillToEdit() {
			this.homePage.description = this.SEO.find(c => c.key === 'home_description')?.value;
			this.homePage.keywords = this.SEO.find(c => c.key === 'home_keyword')?.value;
			
			this.products.description = this.SEO.find(c => c.key === 'products_description')?.value;
			this.products.keywords = this.SEO.find(c => c.key === 'products_keyword')?.value;
			
			this.news.description = this.SEO.find(c => c.key === 'news_description')?.value;
			this.news.keywords = this.SEO.find(c => c.key === 'news_keyword')?.value;
			
			this.services.description = this.SEO.find(c => c.key === 'services_description')?.value;
			this.services.keywords = this.SEO.find(c => c.key === 'services_keyword')?.value;
			
			this.albums.description = this.SEO.find(c => c.key === 'albums_description')?.value;
			this.albums.keywords = this.SEO.find(c => c.key === 'albums_keyword')?.value;
			
			this.aboutUs.description = this.SEO.find(c => c.key === 'aboutus_description')?.value;
			this.aboutUs.keywords = this.SEO.find(c => c.key === 'aboutus_keyword')?.value;
			
			this.isInputsChanged = false;
		}
	},

	created() {
		if (!multiLangInputs) {
			if (Object.keys(this.SEO).length === 0) {
				this.fetchSEOConfigs();
			} else {
				this.fillToEdit();
			}
		} else {
			this.fetchSEOConfigs();
		}
	}
}
</script>