import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		products: []
	},

	actions: {
		fetchAll({ commit }, { useOrder }) {
			return axios.get('/products', {
				params: { useOrder }
			}).then(({ data }) => {
				const storeData = data.filter(c => !useRestore.products ? !c.deletedAt : true);
				commit(types.STORE_PRODUCTS, storeData);
				return storeData;
			})
		},
		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/products/${slug}`, configs).then(({ data }) => data)
		},

		create({ commit }, {
			name, summary, code, isFeatured, inHomePage, price, secondPrice, dateOfPublication, description, categoryIds, filterIds, keywordIds, descriptionSeo, keywordsSeo, image, imagePath, imageName, filePath, fileName, acceptLanguage
		}) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			const requestData = { name, summary, code, price, secondPrice, dateOfPublication, description, descriptionSeo, keywordsSeo, image, imageName, filePath, fileName };

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});
			
			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			formData.append('isFeatured', isFeatured ? 1 : 0);
			formData.append('inHomePage', inHomePage ? 1 : 0);

			if (categoryIds) {
				categoryIds.forEach(id => {
					formData.append('categoryIds[]', id);
				});
			}
			if (filterIds) {
				filterIds.forEach(id => {
					formData.append('filterIds[]', id);
				});
			}
			if (keywordIds) {
				keywordIds.forEach(id => {
					formData.append('keywordIds[]', id);
				});
			}

			return axios.post('/products', formData, configs).then(({ data }) => data)
		},

		update({ commit }, { 
			id, name, summary, code, isFeatured, inHomePage, price, secondPrice, dateOfPublication, description, categoryIds, filterIds, keywordIds, descriptionSeo, keywordsSeo, image, imagePath, imageName, filePath, fileName, acceptLanguage
		}) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			const requestData = { name, summary, code, price, secondPrice, dateOfPublication, description, descriptionSeo, keywordsSeo, image, imageName, filePath, fileName };

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			});

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));
			
			formData.append('isFeatured', isFeatured ? 1 : 0);
			formData.append('inHomePage', inHomePage ? 1 : 0);

			if (categoryIds) {
				categoryIds.forEach(id => {
					formData.append('categoryIds[]', id);
				});
			}
			if (filterIds) {
				filterIds.forEach(id => {
					formData.append('filterIds[]', id);
				});
			}
			if (keywordIds) {
				keywordIds.forEach(id => {
					formData.append('keywordIds[]', id);
				});
			}
			formData.append('_method', 'PUT');

			return axios.post(`/products/${id}`, formData, configs).then(({ data }) => data)
			// headers: { 'content-type': 'multipart/form-data' } 
		},

		uploadImages({ commit }, { id, images, imagesPaths, imagesNames }) {
			const formData = new FormData();
			images.forEach(image => {
				formData.append('images[]', image);
			});
			imagesPaths.forEach(path => {
				formData.append('imagesPaths[]', path.slice(path.indexOf('/', 1)));
			});
			imagesNames.forEach(name => {
				formData.append('imagesNames[]', name);
			});

			return axios.post(`/products/${id}/add-images`, formData)
		},

		deleteImage({ commit }, { id, imageId }) {
			return axios.delete(`/products/${id}/images/${imageId}`)
		},

		changeState({ commit }, { id }) {
			return axios.post(`/products/${id}/change-status`, { _method: 'PUT' });
		},
		
		changeOrder({ commit }, { id, order }) {
			return axios.post(`/products/${id}/change-order`, { num: order, _method: 'PUT' });
		},

		delete({ commit }, { id }) {
			return axios.delete(`/products/${id}`)
		},
		restore({ commit }, { id }) {
			return axios.patch(`/products/${id}`)
		},
	},

	mutations: {
		[types.STORE_PRODUCTS](state, products) {
			state.products = products;
		}
	},

	getters: {
		getFilterById: state => id => state.products.find(c => c.id === id),
		getFilterBySlug: state => slug => state.products.find(c => c.slug === slug),
	}
}